import { createApp } from 'vue';
import App from './App.vue';
import store from './common/store';
import router from './common/router';

const app = createApp(App);

/**
 * 공통 콤포넌트 선언
 */
// 공통함수
import global from './common/utils/Global';
app.use(global);

// DatePicker
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
app.use(VCalendar, {});

// 대화상자
import VueDialog from '@/components/vue-dialog';
app.use(VueDialog);

app.use(store);
app.use(router);

app.mount('#app');
