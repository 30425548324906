import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

//export default new Vuex.Store({
const store = createStore({
	plugins: [createPersistedState()],
	state: {
		session: null,
		user: null,
		selectDate : null,
		//maintenance: null, // 시스템점검 정보
		//accessdenied: false,
		//mainPath: null, // 메인화면 경로
		//pathTitle: null, // 경로에 대한 화면명
		//redirectPath: null, // 로그인 후 이동할 페이지
		sendSmsCnt: null, // SmsTemp 문자전송 제한
		sendSmsExCnt: null, // SmsTempExchg 문자전송 제한
	},
	mutations: {
		session(state, token) {
			let date = new Date();

			date.setMinutes(date.getMinutes() + 30); // 세션 타임아웃 시간 30분
			state.session = {
				token: token,
				expire: date,
			};
		},
		user(state, user) {
			state.user = user;
		},
		selectDate(state, selectDate) {
			state.selectDate = selectDate;
		},
		maintenance(state, data) {
			if (data) {
				if (state.maintenance == null) {
					state.maintenance = {
						start: data.start,
						end: data.end,
					};

					// 로그아웃 처리 후 점검안내 페이지로 이동
					state.session = null;
					state.user = null;
					state.cart = null;
					window.location.href = '/system';
				}
			} else {
				state.maintenance = null;
				window.location.href = '/login';
			}
		},
		otpRequest(state) {
			let current = new Date().getTime();

			// 최초 인증이후 경과시간이 1분 미만인 경우 기존 횟수+1, 아니면 최초인증시간과 카운트 초기화
			if (state.otpRequest != null && current - state.otpRequest.time < 60 * 1000) {
				state.otpRequest.count++;
			} else {
				state.otpRequest = {
					time: current,
					count: 1,
				};
			}
		},
		sendSmsCnt(state) {
			let current = new Date().getTime();

			if (state.sendSmsCnt != null && current - state.sendSmsCnt.time < 60 * 1000) {
				state.sendSmsCnt.count++;
			} else {
				state.sendSmsCnt = {
					time: current,
					count: 1,
				};
			}
		},
		sendSmsExCnt(state) {
			let current = new Date().getTime();

			if (state.sendSmsExCnt != null && current - state.sendSmsExCnt.time < 60 * 1000) {
				state.sendSmsExCnt.count++;
			} else {
				state.sendSmsExCnt = {
					time: current,
					count: 1,
				};
			}
		},
		accessdenied(state, isAccessDenied) {
			state.accessdenied = isAccessDenied;

			if (isAccessDenied) {
				window.location.href = '/access';
			} else {
				window.location.href = '/';
			}
		},
		userMenu(state, userMenu) {
			state.userMenu = userMenu;
		},
		loginKey(state, loginKey) {
			state.loginKey = loginKey;
		},
		location(state, location) {
			state.location = location;
		},
		locationPopYn(state, locationPopYn) {
			state.locationPopYn = locationPopYn;
		},
		mainPath(state, value) {
			state.mainPath = value;
		},
		pathTitle(state, value) {
			state.pathTitle = value;
		},
		redirectPath(state, value) {
			state.redirectPath = value;
		},
		logout(state, value) {
			state.session = null;
			state.user = null;
			state.userMenu = null;
			state.location = null;

			//console.log(value.header.message);
			let message = '';
			if (value && value.header) {
				message = value.header.message;
			}

			if (message.indexOf('Timeout') > -1) {
				window.location.href = '/error/404-timeout';
			} else if (message.indexOf('Duplicate') > -1) {
				window.location.href = '/error/404-duplicate';
			} else {
				window.location.href = '/auth/login';
			}
		},
	},
	actions: {},
	modules: {},
});

export default store;
