import axios from 'axios';
import store from '@/common/store';
import { events } from '@/common/utils/events';
import { messages, excludePaths } from '@/common/utils/Messages';
//import router from '@/router';

/*
    axios 인스턴스를 생성합니다.
    생성할때 사용하는 옵션들 (baseURL, timeout, headers 등)은 다음 URL에서 확인할 수 있습니다.
    https://github.com/axios/axios 의 Request Config 챕터 확인
*/
const instance = axios.create({
	baseURL: process.env.VUE_APP_HEROKU_API_URL,
	timeout: 10000,
	//,headers: {'Content-Type': 'multipart/form-data'}
});

instance.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		if (store.state.session != null && store.state.session.token != null) {
			config.headers.Authorization = 'Bearer ' + store.state.session.token;
		}

		if (location.protocol == 'https:') {
			config.baseURL = config.baseURL.replace('http:', 'https:');
		}

		//console.log(location.protocol);
		//console.log(config.baseURL);

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
instance.interceptors.response.use(
	function (response) {
		events.emit('refreshToken');
		/*
            http status가 200인 경우
            응답 바로 직전에 대해 작성합니다. 
            .then() 으로 이어집니다.
        */
		//let config = response.config;

		return response;
	},

	function (error) {
		/*
            http status가 200이 아닌 경우
            응답 에러 처리를 작성합니다.
            .catch() 으로 이어집니다.    
        */

		//console.log('Send.js error', error);
		//console.log('Send.js excludePaths', excludePaths);

		let messageKey = '';
		let messageArgs = [];

		if (error.response) {
			messageKey = error.response.data.message;

			// 데이터가 있는 경우 추출
			messageArgs = [];
			if (messageKey.indexOf('|') > -1) {
				messageArgs = messageKey.split('|');
				messageKey = messageArgs[0];
				messageArgs = messageArgs.slice(1);

				error.response.data.params = messageArgs;
			}

			error.response.data.key = messageKey;
			error.response.data.message = messages[messageKey];
			console.log('messageKey', messageKey);

			//console.log('Send.js error', error.response);
			if (error.response.status == 401 || messageKey == 'Auth.InvalidToken') {
				// 권한없음 에러 시 로그인 페이지로 이동
				window.noDialog = true;
				store.commit('logout', error.response.data);
			} else if (error.response.status == 500) {
				if (error.response.data.status == 900 && store.state.maintenance == null) {
					// 시스템점검중
					instance.get('/system/info/maintenance').then((res) => {
						store.commit('maintenance', res.data);
					});
				}

				if (error.response.data.status == 901 && store.state.accessdenied == false) {
					store.commit('accessdenied', true);
				}
			}
		} else {
			if (error.message.indexOf('timeout') > -1) {
				messageKey = 'Server.Timeout';
			} else if (error.message == 'Network Error') {
				messageKey = 'Server.NetworkError';
			}
		}

		// 정제된 메세지가 아닌 경우 메세지 정제
		if (!messages.hasOwnProperty(messageKey)) {
			messageKey = 'Server.Error';
			console.log(messageKey);
		}

		// 메세지에 데이터 주입
		let message = messages[messageKey];
		for (let i = 0; i < messageArgs.length; i++) {
			let regex = new RegExp('[/{' + i + '/}]+', 'g');
			message = message.replace(regex, messageArgs[i]);
		}
		//console.log('replaced message', message);

		if (excludePaths.indexOf(error.config.url) == -1) {
			events.emit('showDialog', message);
		} else {
			try {
				error.response.data.message = message;
			} catch (e) {}
		}

		return Promise.reject(error);
	}
);

// 생성한 인스턴스를 익스포트 합니다.
export default instance;
