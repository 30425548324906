export const messages = {
	'Server.Error': '서버에 오류가 발생했습니다.<br>관리자에게 문의바랍니다.',
	'Server.Timeout': '서버와의 연결이 불안정합니다.<br>잠시 후 다시 시도하시길 바랍니다.',
	'Server.NetworkError': '서버에 연결할 수 없습니다.<br>관리자에게 문의바랍니다.',

	'Auth.PermissionDenied': '접근 권한이 없습니다.',
	'Auth.NotApproved': '지점 관리자에게 승인을 요청하십시오.',
	'Auth.InvalidPassword': '사번 또는 비밀번호가 유효하지 않습니다.',
	'Auth.FailTooMuch': '인증 실패로 {0}분간 로그인 요청 처리가 불가합니다.<br>관리자에게 문의바랍니다.',

	'Auth.NoUser' : '해당하는 아이디가 존재하지 않습니다',
	'Auth.ExpiredPassword' : '비밀번호가 만료 되었습니다. 지점 관리자에게 문의하십시오.',
	'Auth.Join.Duplicated': '이미 등록된 아이디입니다.',

	'Auth.Password.NoUser': '사용자 정보가 없습니다.',
	//'Auth.Password.OnceDay': '비밀번호는 하루에 한 번 변경 가능합니다.',
	'Auth.Password.OnceDay': '오늘 비밀번호 등록/변경한 이력이 있습니다. <br>1일 1회 변경가능한 보안정책에 따라<br>현재 비밀번호 변경이 불가합니다.<br>관리자에게 문의하세요.',
	'Auth.Password.InvalidPassword': '현재 비밀번호가 유효하지 않습니다.',
	'Auth.Password.Duplicated': '최근 10회 이내 사용한 비밀번호로 변경할 수 없습니다.',

	'Auth.Find.NoCert': '비밀번호 찾기 인증정보가 없습니다.',
	'Auth.Find.Expired': '비밀번호 찾기 인증키가 만료되었습니다.',
	'Auth.APICallOver': '통신 요청 횟수가 초과되었습니다.',
};

export const excludePaths = ['/auth/login'];
//export const excludeKeys = ['Auth.Find.'];
