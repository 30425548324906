import Send from '../utils/Send.js';

export default {
	login(id, password) {
		return Send({
			url: '/auth/login',
			method: 'post',
			params: {
				id: id,
				password: password,
			},
		});
	},
	join(param) {
		return Send({
			url: '/auth/join',
			method: 'post',
			params: param,
		});
	},
	updatePassword(param) {
		return Send({
			url: '/auth/password',
			method: 'put',
			params: param,
		});
	},
	findPassword(id) {
		return Send({
			url: '/auth/find',
			method: 'post',
			params: {
				id: id,
			},
		});
	},
	getPasswordCert(certKey) {
		return Send({
			url: '/auth/find',
			method: 'get',
			params: {
				certKey: certKey,
			},
		});
	},
	refresh(token) {
		return Send({
			url: '/auth/refresh',
			method: 'get',
			params: {
				token: token,
			},
		});
	},

	getBranch(param) {
		return Send({
			url: '/auth/branch',
			method: 'get',
			params: {
				firstChar: param,
			},
		});
	},
};
