<template>
    <component :is="layout" :layoutName="layoutName" >
        <slot/>
    </component>
    <vue-dialog />
</template>

<script>
import $ from 'jquery';
import Default from '@/layouts/Default.vue';
import NoHeader from '@/layouts/NoHeader.vue';

export default {
    name: 'RootLayout',
    props: {
        layoutName: {
            type: String,
            default: 'default'
        }
    },
    components: {
        Default,
        NoHeader,
    },
    data: () => ({
        lastPath: null,
    }),
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                //console.log('====== base layout watch route ======');

                // 동일 경로에서는 스크롤 강제 이동 방지 (리스트-뷰인 경우 뷰에서 리스트로 돌아오는 경우)
                //if (this.lastPath != to.path) { // 패스로 비교
                if (from && from.matched[0] != to.matched[0]) { // 매칭되는 라우터로 비교
                    //console.log('scroll to top');
                    $('.contain_wrapper_prism').scrollTop(0);
                }

                //this.lastPath = to.path;

                //console.log('====== //base layout watch route ======');
            }
        }
    },
    computed: {
        layout() {
            //console.log('====== base layout layout2 ======');
            //console.log(this.$route.meta.layout);

            //console.log('====== //base layout layout2 ======');
            return (this.$route.meta.layout || Default);
        }
    },
}
</script>

<style>
    @import '~@/assets/css/common.css';
</style>