<template>
	<div class="header">
		<div class="navBar">
			<ul class="menuList">
				<li v-for="menu in menuList">
					<a :class="[{ active: $route.name == menu.route }, menu.route]" @click="goTo(menu)">
						{{ menu.name }}
					</a>
				</li>
			</ul>
			<ul class="utilBox">
				<li v-if="$store.state.user">
					<span>{{ $store.state.user.branchName }}-{{ $store.state.user.zoneCode }}</span>
				</li>
				<li><a @click="$router.push('/auth/password')"></a></li>
				<li><a @click="logout"></a></li>
			</ul>
		</div>
	</div>
</template>
<script>
import $ from 'jquery';

export default {
	data() {
		return {
			menuList: [
				{ name: '주문현황', route: 'ReportOrder' },
				{ name: '문자발송 현황', route: 'CustomerSms' },
        { name: '당일 인수량', route: 'StockForReady' },
			],
			isMenuOpen: false,
			isShowTitle: true,
			title: '',
			targetSystemCd: 'BZ_FO',
			active: false,
      stockForReadyYn : false,
		};
	},
	watch: {
		$route: {
			immediate: true,
			async handler(route) {
				if (this.$store.state.user && route.name != 'Login' && route.name != null) {
					/*
                        //토큰 재생성
                        if (this.$store.state.session != null) {
                            var expireTime = new Date(this.$store.state.session.expire).getTime()
                            var currentTime = new Date().getTime();

                            var diff = Math.floor((expireTime - currentTime) / 1000 / 60);
                            if (0 <= diff && diff <= 5) {
                                Login.refresh({
                                        empCd: this.$store.state.user.empCd
                                    })
                                    .then(res => {
                                        this.$store.commit('session', res.data.body.token);
                                        console.log('토큰재생성')
                                    })
                            }
                        }
                        */
				}
        if(route.name=='StockForReady') {
          this.StockForReadyYn=true;
        }else {
          this.StockForReadyYn=false;
        }
			},
		},
	},
	methods: {
		toggleMenu: function () {
			this.isMenuOpen = !this.isMenuOpen;
		},
		closeChildMenu(menuId) {
			let $target = $('[menu-id=' + menuId + ']');
			$target.hide();
			$target.parent().find('.second_menu').show();
		},
		goTo(menu) {
			if (menu.route == 'CustomerSms') {
        if(this.StockForReadyYn) {
          this.$dialog('점포 선택이 필요 합니다. </br> 주문현황메뉴에서 조회가 필요한 소매점의 문자 아이콘을 선택해주세요.');
        }else {
          this.$dialog('점포명 또는 문자발송 조회 버튼을 클릭해주세요.');
        }
				return;
			}

			this.$router.push({ name: menu.route });
		},
		goBack() {
			history.back();
		},
		goMain() {
			this.$router.push({ path: this.$store.state.mainPath });
		},
		logout: function () {
			this.$dialog({
				type: 'confirm',
				message: '로그아웃하시겠습니까?',
				callback: (res) => {
					if (res) {
						this.$store.commit('logout');
					}
				},
			});
		},
	},
};
</script>
