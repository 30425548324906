<template>
    <div class="wrapper">
        <Header/>
        <div class="container">
            <div class="contents">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './include/Header.vue';

export default {
    name: 'Layout',
    components: {
        Header,
    }
}
</script>
