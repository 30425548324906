import mitt from 'mitt';
import moment from 'moment';

export default {
	install: (app) => {
		let base = this;
		app.config.globalProperties.$app = app;

		// 자식 변수들의 값 초기화
		const resetChildVars = (params) => {
			Object.entries(params).forEach((param) => {
				params[param[0]] = '';
			});
		};
		app.config.globalProperties.$resetChildVars = resetChildVars;

		// 파라미터를 FormData로 복사
		const copyParamsToFormData = (params, formData) => {
			Object.entries(params).forEach((param) => {
				formData.set(param[0], params[param[0]]);
			});
			return formData;
		};
		app.config.globalProperties.$copyParamsToFormData = copyParamsToFormData;

		// 텍스트를 HTML로 변환
		const text2Html = (params) => {
			let result = params;

			result = result.replace(/\n/g, '<br>');

			return result;
		};
		app.config.globalProperties.$text2Html = text2Html;

		app.config.globalProperties.$isEmpty = (value) => {
			return !value || value == 'null' || value == null || value == '' || value == undefined || value.length == 0;
		};

		app.config.globalProperties.$format = {
			currency(value) {
				let result = '';

				if (value) {
					result = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				}

				return result;
			},
			date(value) {
				return moment(value).format('YYYY-MM-DD');
			},
			dateTime(value) {
				return moment(value).format('YYYY-MM-DD HH:mm:ss');
			},
		};

		app.config.globalProperties.$rules = {
			require(value, length) {
				let result = '';

				if (!value || value.length == 0) {
					return '필수값을 입력해주세요.';
				}

				if (length && value.length < length) {
					result = length + '자 이상 입력해주세요.';
				}

				return result;
			},
			password(value, valueConfirm) {
				let result = '';

				let required = this.require(value + valueConfirm);
				if (required) {
					return required;
				}

				if (value != valueConfirm) {
					result += '<br>두 개의 비밀번호가 일치하지 않습니다.';
				}

				if (value.length < 9) {
					result += '<br>9자 이상 입력해주세요.';
				}

				if (!/\d/.test(value)) {
					result += '<br>숫자를 포함하여 입력해주세요.';
				}

				if (!/[A-Za-z]/.test(value)) {
					result += '<br>문자를 포함하여 입력해주세요.';
				}

				let specialChars = ['!', '@', '#', '$', '%'];
				if (!/!|@|#|\$|%/.test(value)) {
					result += '<br>특수문자(!@#$%)를 포함하여 입력해주세요.';
				} else {
					const matchCount=value.match(/!|\$/g);
					let count=null;
					if(matchCount!=null) {
						count = matchCount.filter(item => item !== '').length;
					}

					if (count>2){
						result += '<br>특수문자(!$)의 개수는 3개이상 사용불가합니다.';
					}
				}

				if (result.length > 0) {
					result = result.substr(4);
				}

				return result;
			},
			email(value) {
				let result = '';

				let required = this.require(value);
				if (required) {
					return required;
				}

				if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
					result = '메일 형식이 잘못 되었습니다.';
				}

				return result;
			},
		};

		// 글로벌 이벤트 처리
		app.config.globalProperties.$events = mitt();

		// 글로벌 날짜 처리
		app.config.globalProperties.$moment = moment;
	},
};
