<template>
	<RootLayout :layoutName="layoutName">
		<router-view />
	</RootLayout>
</template>

<script>
import RootLayout from '@/layouts/RootLayout.vue';
import { events } from '@/common/utils/events';
import Auth from '@/common/api/Auth';

export default {
	name: 'App',
	components: {
		RootLayout,
	},
	data: () => {
		return {
			layoutName: null,
			isBusy: false,
		};
	},
	watch: {
		$route: {
			immediate: true,
			async handler(route) {
				this.refreshToken();
			},
		},
	},
	created() {
		document.title = 'DTE Mobile Report';

		//this.$events.on('setLayout', this.setLayout);

		events.on('showDialog', this.showDialog);
		events.on('refreshToken', this.refreshToken);

		//console.log('App.vue created');
	},
	methods: {
		showDialog(message) {
			//console.log('Event!! showDialog', message);
			this.$dialog(message);
		},
		refreshToken() {
			//토큰 재생성
			if (this.$store.state.session != null && this.isBusy == false) {
				this.isBusy = true;
				let expireTime = this.$moment(this.$store.state.session.expire);
				let diff = expireTime.diff(new Date(), 'minute'); // 토큰만료시간 - 현재시간
				console.log('expire diff', diff);

				// 5분마다 토큰 갱신하여 25분동안 페이지 이동이 없어도 로그인 유지
				if (diff < 25) {
					Auth.refresh(this.$store.state.session.token)
						.then((res) => {
							this.$store.commit('session', res.data.token);
							console.log('토큰재생성', res);
						})
						.finally(() => {
							this.isBusy = false;
						});
				}
			} /*else if(this.$store.state.session == null) {
          alert('세션이 만료되었으니, 다시 로그인 바랍니다.');
          this.$router.push('/auth/login');
      }*/
		},
	},
};
</script>
