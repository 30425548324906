import { createWebHistory, createRouter } from 'vue-router';

import store from '@/common/store';
import Order from '@/common/api/Order';
const routes = [
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: () => import('@/views/error/404.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
	},
	{
		path: '/error/404-timeout',
		name: '404-timeout',
		component: () => import('@/views/error/401-timeout.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
	},
	{
		path: '/auth/login',
		name: 'Login',
		component: () => import('@/views/auth/Login.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true, //권한이 없어도 들어가짐
		},
	},
	{
		path: '/auth/join',
		name: 'Join',
		component: () => import('@/views/auth/Join.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
	},
	{
		path: '/auth/password',
		name: 'Password',
		component: () => import('@/views/auth/Password.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
	},
	{
		path: '/auth/find',
		name: 'Find',
		component: () => import('@/views/auth/Find.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
	},
	{
		path: '/report/order',
		name: 'ReportOrder',
		component: () => import('@/views/report/Order.vue'),
		alias: ['/'],
	},
	{
		path: '/report/stock',
		name: 'StockForReady',
		component: () => import('@/views/report/StockForReady.vue'),
		alias: ['/'],
	},
	{
		path: '/customer/:id/sms',
		name: 'CustomerSms',
		component: () => import('@/views/customer/Sms.vue'),
		meta: {
			unauthorized: true,
		},
		props: true,
	},
	{
		path: '/report/:id/:searchDate/SmsTemp',
		name: 'ReportSmsTemp',
		component: () => import('@/views/report/SmsTemp.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
		children: [
			{
				path:':zoneCode',
				component: () => import('@/views/report/SmsTemp.vue'),
				meta: {
					layout: 'no-header',
					unauthorized: true,
				}
			},
		],
		props: true,
	},
	{
		path: '/report/:shortUrl',
		name: 'ReportSmsTemp1',
		component: () => import('@/views/report/SmsTemp.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
		props: true,
	},
	{
		path: '/customer/:id/:searchDate/:exYn/:orderNumber/ExchangeReturn',
		name: 'ExchgReturn',
		component: () => import('@/views/customer/ExchangeReturn.vue'),
		meta: {
			unauthorized: true,
		},
		props: true
	},
	{
		path: '/customer/:id/:searchDate/SmsTempExchg',
		name: 'SmsTempExchg',
		component: () => import('@/views/report/SmsTempExchg.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
		children: [
			{
				path:':zoneCode',
				component: () => import('@/views/report/SmsTempExchg.vue'),
				meta: {
					layout: 'no-header',
					unauthorized: true,
				}
			},
		],
		props: true,
	},
	{
		path: '/customer/:shortUrl',
		name: 'ReportSmsTempExchg',
		component: () => import('@/views/report/SmsTempExchg.vue'),
		meta: {
			layout: 'no-header',
			unauthorized: true,
		},
		props: true,
	},
];

const router = new createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	//console.log('router.beforeEach to :', to);
	//console.log('router.beforeEach to.matched.some(record => record.meta.unauthorized) :', to.matched.some(record => record.meta.unauthorized));
	//console.log('router.beforeEach store.state.session :', store.state.session);
	//console.log('router.beforeEach store.state.user :', store.state.user);

	let mainUrl = '/report/order';

	// 권한이 필요 없는 페이지이거나 토큰이 있는 경우 통과
	if (to.fullPath.indexOf('assets') > -1 || to.matched.some((record) => record.meta.unauthorized) || store.state.session) {
		/*
        // 메인페이지 경로 확인
        if (to.path == mainUrl) {
            if (store.state.redirectPath != null) {
                // 리다이렉트 페이지가 정의된 경우
                let path = store.state.redirectPath;
                store.commit('redirectPath', null);
                return next(path);
            } else if (store.state.mainPath != mainUrl) {
                // 메인페이지가 재정의된 경우
                return next(store.state.mainPath);
            }
        }
        */

		if(to.params.shortUrl!=null) {
			console.log("fullPath",to.fullPath)
			return next((await Order.getUrl(to.params.shortUrl)).data);
		}
			return next();

	}

	// 로그인이 되어있는데 로그인메뉴로 이동 시 메인으로 강제 이동
	//console.log(to.matched.some(record => (record.path.indexOf('/auth') == 0)));
	if (store.state.session && (to.path == '/' || to.path.indexOf('/auth') == 0) && to.path.indexOf('/auth/login') != 0) {
		return next(mainUrl);
	}

	//console.log('router.beforeEach to.path : ', to.path);
	store.commit('redirectPath', to.path);

	// 로그인페이지로 이동
	return next('/auth/login');
});

export default router;
