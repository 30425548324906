import Send from '../utils/Send.js';

export default {
	get(param) {
		return Send({
			url: '/order',
			method: 'get',
			params: param,
		});
	},

	getAll(param) {
		return Send({
			url: '/orderAll',
			method: 'get',
			params: param,
		});
	},

	getSms(param) {
		return Send({
			url: '/selectReceipt',
			method: 'get',
			params: param,
		});
	},
	sendSms(param) {
		return Send({
			url: '/sendReceipt',
			method: 'get',
			params: param,
		});
	},
	getUrl(sUrl) {
		return Send({
			url: '/getUrl',
			method: 'get',
			params: {
				sUrl: sUrl,
			},
		});
	},
	getExchgSms(param) {
		return Send({
			url: '/exchangeReceipt',
			method: 'get',
			params: param,
		});
	},
};
